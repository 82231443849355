import React from 'react'
import { useState } from 'react';
import { client } from '../../../../client';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

function Morestories(props) {
  const [data, setData] = useState([]);
  const languageKey = localStorage.getItem('locale');
  const param=useParams();


  const getBannerData = async () => {
      try {
          const response = await client.getEntries({ content_type: 'testimonial', locale: languageKey });
          console.log(response.items)
          let arrtemp = [];

          // Loop through response items and check for featured
          for (let i = 0; i < response.items.length; i++) {
            if (response.items[i].fields.featured === true) {
              arrtemp.push(response.items[i]);
            }
          }
      
          // Update the state with featured items
          setData(arrtemp);
      } catch (error) {
          console.log(error);
      }
  };

  function truncateTextdiscription(text, limit) {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length <= limit) return text;
    return words.slice(0, limit).join(' ') + '...';
}



  useEffect(() => {
    getBannerData();
}, []);
  return (
<section>
  <div className="more_stories_section">
    <div className="container">
      <div className="heading_sec">
        <h2>
          <span>
            <b>{props.rem}</b>
          </span>
        </h2>
      </div>
      <div className="row ms_outer">
        {data.filter(item => item?.sys?.id !== param.id).map((item,index)=>{
          return(<>
           <div key={index} className="col-lg-6 pe-xl-5 pe-lg-3 mb-3">
          <div className="row">
            <div className="col-md-6">
              <div className="ms_img">
                <span>
                  <img
                    src={item?.fields?.image?.fields?.file?.url} alt={item?.fields?.image?.fields?.description}
                    className="img-fluid w-100"
                  />
                </span>
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="ms_content">
                <div className="para_sec">
                  <p>
                  {truncateTextdiscription(item?.fields?.discription1,40)}
                  </p>
                </div>
                <div className="btn_sec ms_btn">
                  <a className="site_btn" href={languageKey=='fr'?(`/stories-details/${item.sys.id}/fr`):(`/stories-details/${item.sys.id}`)}>
                  {props.rem}
                    <img
                      src="/images/btn_arrow.png"
                      alt="btn_arrow"
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
          </>)
        })}
       
      
      </div>
    </div>
  </div>
</section>

  )
}

export default Morestories