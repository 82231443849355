import React, { useEffect } from "react";

const InjectScriptFromAPI = ( scriptString ) => {
  useEffect(() => {
    // Create a temporary DOM element to hold the string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = scriptString.datacode;
    // Extract all script tags from the temporary DOM
    const scriptTags = tempDiv.getElementsByTagName('script');
    const scriptElements = Array.from(scriptTags);

    scriptElements.forEach((scriptElement) => {
      const newScript = document.createElement('script');

      // Copy attributes from the original script tag
      Array.from(scriptElement.attributes).forEach(attr => {
        newScript.setAttribute(attr.name, attr.value);
      });

      // If the script contains inline code, copy it as well
      if (scriptElement.innerHTML) {
        newScript.innerHTML = scriptElement.innerHTML;
      }

      // Append the script to the head
      document.head.appendChild(newScript);
    });

    // Cleanup: remove the injected scripts when the component unmounts
    return () => {
    //   scriptElements.forEach(scriptElement => {
    //     document.head.removeChild(scriptElement);
    //   });
    };
  }, [scriptString]);

  return null;
};

export default InjectScriptFromAPI;
