import React,{useEffect}from 'react';
import { client } from '../../../../client';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

function Underdevelopment() {
  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getBannerdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'underDevelopment',locale: languagekey })
      setdata(response.items);
      setLoading(false)
    }
    catch(error){
      console.log(error);
    }
  }
  useEffect(()=>{
    getBannerdata();
  },[]);

  return (
    <section>
    <div className="dev_section">
      <img
        src="/images/cone_back.png"
        alt="cone"
        className="img-fluid cone_back"
      />
      <div className="dev_slide">
        <img
          src="/images/cone_bg.png"
          alt="cone_bg"
          className="img-fluid w-100"
        />
        <div className="dev_outer">
          <div className="container">
            <div className="heading_sec">
              <img src="/images/cone.png" alt="cone" className="img-fluid" />
              <h2>{data[0]?.fields?.heading}</h2>
              <p>
              {data[0]?.fields?.content}
              </p>
            </div>
            <div className="dev_block">
              <p>
              {data[0]?.fields?.subheading}
              </p>
              {data[0]?.fields?.buttonText? ( <div className="btn_sec dev_btn_sec">
                
                <NavLink to={data[0]?.fields?.buttonLink} className="site_btn">
                {data[0]?.fields?.buttonText}
                  <img
                    src="/images/btn_arrow.png"
                    alt="btn_arrow"
                    className="img-fluid"
                  />
                </NavLink>
              </div>):(<>
              
              </>)}
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  )
}

export default Underdevelopment