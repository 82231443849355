import React, { useEffect, useState } from 'react';
import { client } from '../../client';
import { useLocation, useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import SearchBar from './SearchBar';

function Topnav() {
  const [data, setdata] = useState([]);
  const navigate = useNavigate(); // Use the useNavigate hook
  const [ loading,setLoading]=useState(true);
  const setkeylocale = (key) => {
    localStorage.setItem('locale', key);
  };

  const languagekey = localStorage.getItem('locale');

  const getheaderdata = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'topNav',
        locale: languagekey
      });
      setdata(response.items);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean); // Filter out empty segments
  
  useEffect(() => {
    console.log(pathSegments)
  

    if (pathSegments[pathSegments.length - 1] === 'fr') {
      localStorage.setItem('locale', 'fr');
      console.log("here")
      
    } else {
      localStorage.setItem('locale', 'en-US');
      console.log("here 2")
      
    }
    getheaderdata();
  }, [location.pathname]);
  

  const handleLanguageClick = (locale) => {
    // Get the current path segments and filter out empty segments
    let segments = location.pathname.split('/').filter(Boolean); 
  
    let newPath = '';
  
    // Special handling for root path
    if (segments.length === 0) {
      newPath = locale === 'fr' ? '/fr' : '/';
    } else {
      // Remove the 'fr' segment if present
      if (segments.includes('fr')) {
        segments = segments.filter(segment => segment !== 'fr');
      }
  
      if (locale === 'en-US') {
        // For English, join the remaining segments
        newPath = `/${segments.join('/')}`;
      } else if (locale === 'fr') {
        // For French, append '/fr' to the remaining segments
        newPath = `/${segments.join('/')}/fr`;
      }
    }
  
    // Remove any leading or trailing slashes
    newPath = newPath.replace(/\/{2,}/g, '/');
  
    // Ensure newPath is not empty
    if (newPath === '') {
      newPath = locale === 'fr' ? '/fr' : '/';
    }
  
    // Build the full URL with domain
    const fullUrl = `${window.location.origin}${newPath}`;
  
    // Redirect the user to the new path
    window.location.href = fullUrl;
  
    // Set the locale in localStorage
    setkeylocale(locale);
  };
  
  
  
  function checkAccessMethod() {
    const referrer = document.referrer;
    const currentDomain = window.location.origin;
  
    if (referrer === "") {
      console.log("Direct hit or refresh.");
      window.location.reload();

    } else if (referrer.startsWith(currentDomain)) {
      console.log("Internal link within the same website.");
    } else {
      console.log("External link from another website.");
      window.location.reload();
    }
  }
  useEffect(()=>{
    checkAccessMethod();
  },[])
  

  return (
    <>
    {loading ? (<>
    </>):( <div className="header_top_sec">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <div className="th_sec"></div>
          </div>
          <div className="col-md-5">
            <div className="th_right_sec d-flex align-items-center justify-content-end">
              <div className="language_sec">
                <a>
                  <img
                    src={data[0]?.fields?.icon?.fields?.file?.url}
                    alt="h_icon"
                    className="img-fluid"
                  />
                </a>
                <a
                  onClick={() => handleLanguageClick('en-US')}
                  className={pathSegments[pathSegments.length - 1] !== 'fr' ? 'active' : ''}
                >
                  EN
                </a>{" "}
                |{" "}
                <a
                  onClick={() => handleLanguageClick('fr')}
                  className={pathSegments[pathSegments.length - 1] === 'fr' ? 'active' : ''}
                >
                  FR
                </a>
              </div>
              <SearchBar />
            </div>
          </div>
        </div>
      </div>
    </div>)}
    </>
  
     
   
  
  );
}

export default Topnav;
