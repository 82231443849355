import React from 'react'
import Morestories from './Morestories'

function Headlines(props) {

  const data = props.data

  console.log(data)
  return (
    <>
    <section>
    <div className="remarkable_section living_section pb-5 mb-lg-4">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="remarkable_content">
              <div className="heading_sec heading_inner">
                <h2>
                  <span>{data.title}</span>
                </h2>
              </div>
              <div className="para_sec mb-0">
                <p>
                  <b>
                   {data.subHeading}
                  </b>
                </p>
                <p>
                  {data.discription1}
                </p>
                <p>
                  {" "}
                  {data.discription2}
                </p>
                <p>
                  {" "}
                  {data.discription3}
                </p>
                <p>
                  {" "}
                  {data.discription3}
                </p>
                <p>
                  {" "}
                  {data.discription5}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="remarkable_img d-flex">
              <span className="img1 mt-0">
                <img
                  src={data.image?.fields?.file?.url}
                  alt={data.image?.fields?.description}
                  className="img-fluid w-100"
                />
              </span>
              <span className="img2">
                <img
                  src={data.image2?.fields?.file?.url}
                  alt={data.image?.fields?.description}
                  className="img-fluid w-100"
                />
              </span>
            </div>
          </div>
          <div className="col-12">
            <div className="quote_block text-center">
              <div className="para_sec mb-0">
                <p className="mb-0">
                  <span>
                    {data.quote}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
   <Morestories rem={data.moreStories}/>
  </>
  
  )
}

export default Headlines