import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Signupcontent from './Signupcontent';
import { client } from '../../../../client';
import { Link } from 'react-router-dom';

function Form() {
  const initialFormState = {
    firstname: '',
    lastname: '',
    email: '',
    cnfrmemail: '',
    province: '',
    radio: '',
    Storiesandinspiration: false,
    managingrett: false,
    casestra: false,
    whatTypeOfInformationAreYouInterestedInSee: [],
    iagree: false,
    iunderstand: false,
  };

  const [formData, setFormData] = useState(initialFormState);
  const [formValid, setFormValid] = useState(false);
  const [submited, setsubmit] = useState(false);
  const [displayview, setdisplayview] = useState('none');
  const [data, setdata] = useState([]);
  const [province, setdataprovince] = useState([]);
  const [datadescribe, setdatawhatdesc] = useState([]);
  const [datadescribeanothre, setdatawhatdescanother] = useState([]);
  const [language, setLanguage] = useState('en');
  const [thankyou,setthankyou]=useState([]);

  // const dataEnglish = {
  //   one: "Stories and inspiration from other Rett families",
  //   two: "Resources for managing Rett",
  //   three: "Tips and care strategies",
  // };

  // const dataFrench = {
  //   one: "Histoires et inspirations d'autres familles Rett",
  //   two: "Ressources pour gérer Rett",
  //   three: "Conseils et stratégies de soins",
  // };


  const languageKey = localStorage.getItem('locale');
// useEffect(()=>{
//   if(languageKey==="fr"){
//     setLanguage(dataFrench)
//    }else{
//     setLanguage(dataEnglish)
//    }
// },[])

  const validateForm = () => {
    const { firstname, lastname, email, cnfrmemail, province, radio, iagree, iunderstand  ,whatTypeOfInformationAreYouInterestedInSee} = formData;
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const checkwhatTypeOfInformationAreYouInterestedInSee = whatTypeOfInformationAreYouInterestedInSee.length <=0 ? false:true
    return (
      firstname &&
      lastname &&
      email &&
      cnfrmemail &&
      email === cnfrmemail &&
      province &&
      radio &&
      iagree &&
      iunderstand &&
      checkwhatTypeOfInformationAreYouInterestedInSee &&
      re.test(String(email).toLowerCase()) &&
      re.test(String(cnfrmemail).toLowerCase())

    );
  };


  useEffect(() => {
    setFormValid(validateForm());
  }, [formData]);

  const getRetJourneyData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'contactformlabels', locale: languageKey });
      setdata(response.items);
    } catch (error) {
      console.error(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await client.getEntries({ content_type: 'dataProvinceList', locale: languageKey });
      setdataprovince(response.items);
    } catch (error) {
      console.error(error);
    }
  };

  const getDescribe = async () => {
    try {
      const response = await client.getEntries({ content_type: 'dataWhatBestDescribesYouList', locale: languageKey });
      setdatawhatdesc(response.items);
    } catch (error) {
      console.error(error);
    }
  };

  const getAnother = async () => {
    try {
      const response = await client.getEntries({ content_type: 'whatTypeOfInformationAreYouInterestedInSeeList', locale: languageKey });
      setdatawhatdescanother(response.items);
      setLanguage(response.items[0].fields)
    } catch (error) {
      console.error(error);
    }
  };


  const getthankyou = async () => {
    try {
      const response = await client.getEntries({ content_type: 'thankyou', locale: languageKey });
      setthankyou(response.items);
      console.log(response.items)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRetJourneyData();
    getAnother();
    getDescribe();
    getProvince();
    getthankyou();
  }, [languageKey]);

  const handlePutRequest = async (e) => {
    e.preventDefault();

    const url = 'https://api.contentful.com/spaces/kh9oqq79x6cc/environments/master/entries';
    const token = 'CFPAT-2QPAQbSn_1mLQNoHW6sj9O4V1A2rzTD9-Yfls6w-MhM';
    const bodyData = {
      fields: {
        firstName: {
          'en-US': formData.firstname,
        },
        lastName: {
          'en-US': formData.lastname,
        },
        email: {
          'en-US': formData.email,
        },
        province: {
          'en-US': formData.province,
        },
        whatBestDescribesYou: {
          'en-US': formData.radio,
        },
        whatTypeOfInformationAreYouInterestedInSee: {
          'en-US': formData.whatTypeOfInformationAreYouInterestedInSee,
        },
      },
    };

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/vnd.contentful.management.v1+json',
      'X-Contentful-Content-Type': 'contactForm',
    };

    try {
      setdisplayview('block');
      const response = await axios.post(url, bodyData, { headers });
      setFormData(initialFormState);
    } catch (err) {
      console.error(err);
    }
  };

  const update = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox' && (name === 'Storiesandinspiration' || name === 'managingrett' || name === 'casestra')) {
      setFormData((prevState) => {
        const updatedInterestArray = checked
          ? [...prevState.whatTypeOfInformationAreYouInterestedInSee, value]
          : prevState.whatTypeOfInformationAreYouInterestedInSee.filter((item) => item !== value);

        return {
          ...prevState,
          whatTypeOfInformationAreYouInterestedInSee: updatedInterestArray,
          [name]: checked,
        };
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  return (
    <>
      <Signupcontent display={displayview} />
      <section style={{ display: displayview }}>
    <div class="banner_block_section thank_section" >
        <div class="container">
          <div class="thank_block d-flex align-items-center">
            <span><img src="/images/check_icon.png" alt="check_icon" class="img-fluid"/></span>
            <div class="thank_head">
                <h2>{thankyou[0]?.fields?.thankyouMessage}</h2>
            </div>
          </div>
        </div>
      </div> 
  </section>
  <section style={{ display: displayview }}>
    <div class="thank_content">
        <div class="container">
            <div class="para_sec">
                <p class="mb-0">{thankyou[0]?.fields?.belowmessage} <span>{thankyou[0]?.fields?.webName}</span></p>
            </div>
        </div>
    </div>
  </section>

      <section style={{ display: displayview === 'none' ? 'block' : 'none' }}>
        <div className="contact_section">
          <div className="container">
            <form onSubmit={handlePutRequest} className="contact_form d-flex flex-wrap">
              <div className="form_group w-50">
                <label htmlFor="">{data[0]?.fields?.firstName}</label>
                <input
                  type="text"
                  className="input_sec w-100"
                  name="firstname"
                  value={formData.firstname}
                  onChange={update}
                  placeholder="John"
                />
              </div>
              <div className="form_group w-50">
                <label htmlFor="">{data[0]?.fields?.lastName}</label>
                <input
                  type="text"
                  className="input_sec w-100"
                  name="lastname"
                  value={formData.lastname}
                  onChange={update}
                  placeholder="Doe"
                />
              </div>
              <div className="form_group w-50">
                <label htmlFor="">{data[0]?.fields?.emailAddress}</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={update}
                  className="input_sec w-100"
                  placeholder="user@yourdomain.com"
                />
              </div>
              <div className="form_group w-50">
                <label htmlFor="">{data[0]?.fields?.confirmEmailAddress}</label>
                <input
                  type="email"
                  name="cnfrmemail"
                  value={formData.cnfrmemail}
                  onChange={update}
                  className="input_sec w-100"
                  placeholder="user@yourdomain.com"
                />
              </div>
              <div className="form_group w-50">
                <label htmlFor="">{data[0]?.fields?.province}</label>
                <select
                  className="input_sec w-100"
                  name="province"
                  value={formData.province}
                  onChange={update}
                >
                  {province.sort((a, b) => a.fields.order - b.fields.order).map((data, index) => (
                    <option key={index} value={data?.fields?.name}>{data?.fields?.name}</option>
                  ))}
                </select>
              </div>
              <div className="form_group w-50">
                <label htmlFor="">{data[0]?.fields?.whatBestDescribesYou}</label>
                <div className="radio_btn d-flex flex-wrap">
                {[...datadescribe].reverse().map((data, index) => (
  <label key={index} className="container">
    {data?.fields?.name}
    <input
      type="radio"
      name="radio"
      value={data?.fields?.name}
      checked={formData.radio === data?.fields?.name}
      onChange={update}
    />
    <span className="checkmark" />
  </label>
))}
                </div>
              </div>
              <div className="form_group w-100">
                <label htmlFor="">
                  {data[0]?.fields?.whatTypeOfInformationAreYouInterestedInSee}
                </label>
                <div className="checkbox_btn d-flex flex-wrap">
                  <label className="container">
                    {language.one}
                    <input
                      type="checkbox"
                      name="Storiesandinspiration"
                      value="Stories and inspiration from other Rett families"
                      checked={formData.Storiesandinspiration}
                      onChange={update}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="container">
                    {language.two}
                    <input
                      type="checkbox"
                      name="managingrett"
                      value="Resources for managing Rett"
                      checked={formData.managingrett}
                      onChange={update}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="container">
                    {language.three}
                    <input
                      type="checkbox"
                      name="casestra"
                      value="Tips and care strategies"
                      checked={formData.casestra}
                      onChange={update}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="form_group w-100">
                <div className="checkbox_btn check_color">
                  <label className="container w-100">
                    {data[0]?.fields?.termsAndConditionContent}  <Link to={data[0]?.fields?.termsConditionsLink}>{data[0]?.fields?.termsConditions}</Link>.
                    {data[0]?.fields?.iUnderstandThat} <Link to={data[0]?.fields?.privacyPolicyLink}>{data[0]?.fields?.privacyPolicy}</Link>.
                    <input
                      name="iunderstand"
                      checked={formData.iunderstand}
                      onChange={update}
                      type="checkbox" />
                    <span className="checkmark" />
                  </label>
                  <label className="container w-100">
                    {data[0]?.fields?.yesIWouldRecieve}
                    <input
                      name="iagree"
                      checked={formData.iagree}
                      onChange={update}
                      type="checkbox" />
                    <span className="checkmark" />
                  </label>
                  <label className="container w-100" style={{ marginLeft: "-2.5%" }}>
                    {data[0]?.fields?.forMoreInformation}
                  </label>
                </div>
              </div>
              <div className="form_group form_btn w-100">
                {!formValid ? (
                  <button type="submit" style={{ backgroundColor: "#52327966", borderRadius: "15px" }} disabled>
                    {data[0]?.fields?.sbtButton}{" "}
                    <img
                      src="/images/btn_arrow.png"
                      alt="btn_arrow"
                      className="img-fluid"
                    />
                  </button>
                ) : (
                  <button type="submit" className="site_btn">
                    {data[0]?.fields?.sbtButton}{" "}
                    <img
                      src="/images/btn_arrow.png"
                      alt="btn_arrow"
                      className="img-fluid"
                    />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* <ToastContainer /> */}
    </>
  );
}

export default Form;
