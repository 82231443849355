import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../../client';


function Notalone() {

  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'notalone',locale: languagekey })
      setdata(response.items);
      setLoading(false)
  
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getdata();
  },[])

  return (
   <>
   {
    isLoading? (<>Loading....</>):(  <section>
      <div className="remarkable_section living_section">
        <div className="container">
          <div className="heading_sec heading_inner">
            <h2 className="mb-0">
              <span>{data[0]?.fields?.headingColor1} </span> {data[0]?.fields?.headingColor2}
            </h2>
          </div>
        </div>
      </div>
    </section>)
   }
 

   </>
  )
}

export default Notalone