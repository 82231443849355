import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect,useState } from 'react';
import { client } from '../../../client';
import { Link } from 'react-router-dom';
import Notfoundanything from './Sections/Notfoundanything';

function Search() {

    const [results, setResults] = useState([]);
    const languagekey = localStorage.getItem('locale');
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState([]);
    const searchQuery = useParams();
  
    const [getinput,setgetinput]=useState(searchQuery.id)
    const [displyhere,setdisp]=useState({
        display: "none"
    })
    const [displyhere2,setdisp2]=useState({
        display: "block"
    })

    const gedata = async () => {
      try {
        const response = await client.getEntries({ content_type: 'searchitems', locale: languagekey });
        setdata(response.items);
        console.log(response.items)
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };

    function getinputfn(e){
        setgetinput(e.target.value)
    }


    const idToText = {
        whatIsRettSyndromewhatIsRettSyndrome: languagekey === 'fr' ? 'Qu\'est-ce que le syndrome de Rett ?' : 'What is Rett Syndrome ?',
        home: languagekey === 'fr' ? 'Accueil' : 'Home',
        rettStoriesrettStories: languagekey === 'fr' ? 'Histoires de Rett' : 'Rett Stories',
        rettResourcesrettResources: languagekey === 'fr' ? 'Ressources Rett' : 'Rett Resources',
        navigatingLifeWithRettnavigatingLifeWithRett: languagekey === 'fr' ? 'Naviguer avec Rett' : 'Navigating Life With Rett',
        aboutUsabout: languagekey === 'fr' ? 'À propos de nous' : 'About Us'
    };
  
    const idToUrl = {
        whatIsRettSyndromewhatIsRettSyndrome: languagekey === 'fr' ? '/what-is-rett-syndrome/fr/' : '/what-is-rett-syndrome',
        home: languagekey === 'fr' ? '/fr' : '/',
        rettStoriesrettStories: languagekey === 'fr' ? '/rett-stories/fr/' : '/rett-stories',
        rettResourcesrettResources: languagekey === 'fr' ? '/rett-resources/fr/' : '/rett-resources',
        navigatingLifeWithRettnavigatingLifeWithRett: languagekey === 'fr' ? '/navigating-life-with-rett/fr/' : '/navigating-life-with-rett',
        aboutUsabout: languagekey === 'fr' ? '/about-us/fr/' : '/about-us'
    };
    useEffect(()=>{
      gedata();
    },[])

    useEffect(() => {
     
        if (searchQuery.id) {
          const fetchEntries = async () => {
            setLoading(true);
            try {
              const response = await client.getEntries({
                query: searchQuery.id,
                limit: 50,
              });
              console.log(response.items)
              setResults(response.items);
              if(response.items.length==0){
                setdisp({display:"block"})
                setdisp2({display:"none"})
              }
            } catch (error) {
              console.error('Error fetching entries:', error);
            }
            setLoading(false);
          };
          fetchEntries();
        } else {
          setResults([]);
        }
      }, []);
      const renderedIds = new Set();

    function getdatainnerinput(e){
        e.preventDefault();
        if(languagekey === 'fr'){

            window.location.href = `/search/${getinput}/fr`; 
          }
          else{
            window.location.href = `/search/${getinput}`; 
          }
        
      }

  return (
<section className="search_section">
  <div className="breadcrumb_section">
    <div className="container">
      <nav className="breadcrumb_block" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={languagekey=='fr'?('/fr'):('/')}>{languagekey=='fr'?(<>Accueil</>):(<>Home</>)}</Link>{" "}
            <i className="fa-solid fa-chevron-right" />
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data[0]?.fields?.resultfor} '{searchQuery.id}'
          </li>
        </ol>
      </nav>
      <div className="search_block">
        <div className="heading_sec">
          <h1>{data[0]?.fields?.searchResults}</h1>
        </div>
        <div className="th_search ms-0">
            <form onSubmit={getdatainnerinput}>
            <input type="text" placeholder={data[0]?.fields?.placeholder} value={getinput} onChange={getinputfn}/>
          
                    <button type="submit" className="search-button">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
            </form>
          
        </div>
      </div>
    </div>
  </div>
  <div style={displyhere}>
  <Notfoundanything data={data[0]?.fields?.nothingfound}/>
  </div>

  <div className="show_results">
    <div className="container">
      <div className="show_inner">
             
      {results.map((entry) => {
  const sysId = entry?.metadata?.tags[0]?.sys?.id;
  const text = idToText[sysId];
const url = idToUrl[sysId];

  if (text && !renderedIds.has(sysId)) {
    renderedIds.add(sysId);
    return (
        <div className="results_block_outer mt-2">
        <div className="results_block">
          <Link to={`${url}`}>
          
            <div className="heading_sec">
              <h3>
              {text}
              </h3>
             
            </div>
          </Link>
        </div>
       
      </div>
    //   <li key={entry.sys.id} >
    //     <Link to={`${url}`}>{text}</Link>
    //   </li>
    );
  }
  return null;
})}
      
       
      </div>
    </div>
  </div>
</section>

  )
}

export default Search