import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { client } from '../../client'


function Footerbottom() {
  const languagekey = localStorage.getItem('locale');
    const [data,setdata]=useState([])
    const getheaderdata = async (e)=>{
      try {
        const response = await client.getEntries({ content_type: 'topNav',locale: languagekey})
        console.log(response);
        setdata(response.items);
      }
      catch(error){
        // console.log(error);
  }  
    }
    useEffect(()=>{
      getheaderdata();      
      
    },[])

    
    

  return (
    <div>
        <p className='m-auto text-center footerbottom'>
        {data[0]?.fields?.heading}
     
        </p>
    </div>
  )
}

export default Footerbottom