import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../../client'

function Signupcontent(props) {

  const [data,setdata]=useState([])

  const [display,setdisplay]=useState('block')
  const languagekey = localStorage.getItem('locale');
  const getretjournydata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'signupcontet',locale: languagekey })
      setdata(response.items);
    }
    catch(error){
    
    }
  }
  useEffect(()=>{
    getretjournydata();
    if(props.display=='none'){
      setdisplay('block');
    }
    else{
      setdisplay('none');
    }
  },[props.display])

  return (
    <div style={{display: display}}><div className="banner_block_section">
    <div className="container">
      <div className="banner_block">
        <div className="para_sec">
          <p>
          {data[0]?.fields?.para1}
          </p>
          <p>
          {data[0]?.fields?.para2}{" "}
          {data[1]?.fields?.para2}{" "}
          {data[2]?.fields?.para2}{" "}
            <a href={`mailto:${data[0]?.fields?.emailId}`}>
              <u>{data[0]?.fields?.emailId}</u>
            </a>{" "}
            {data[0]?.fields?.para3}
          </p>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default Signupcontent