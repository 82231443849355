import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../../client'
import { Link, NavLink } from 'react-router-dom';


function Navigating() {
  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getheaderdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'navigating',locale: languagekey })
      setdata(response.items);
      setLoading(false);
    }
    catch(error){
      // console.log(error);
}  
  }
  useEffect(()=>{
    getheaderdata();
  },[])
  return (
    <section>
  <div className="navigating_section">
    <div className="container">
      <div className="navigating_content d-flex align-items-center">
        <div className="heading_sec">
          <h2>
            <span className="d-block pb-lg-2">{data[0]?.fields?.heading}</span> {data[0]?.fields?.headingColor}
          </h2>
        </div>
        <div className="para_sec">
          <p>
          {data[0]?.fields?.content}<br/>
          {data[0]?.fields?.content2}<br/>
          {data[0]?.fields?.content3}
          </p>
        </div>
        <div className="btn_sec">
          <NavLink className='site_btn' to= {data[0]?.fields?.buttonLink}>
          {data[0]?.fields?.button}
            <img src="images/btn_arrow.png" alt="btn2" className="img-fluid" />
          </NavLink>
        </div>
      </div>
      <div className="navigating_dektop_sec">
  <div className="navigating_main_sec">
    <img
      src="images/infographic.png"
      alt="infographic"
      className="img-fluid w-100"
    />
    <div className="navigating_icons">
      <Link to="/navigating-life-with-rett#MissedDevelopmentalMilestones">
        <img
          src={data[0]?.fields?.iconWithContent1?.fields?.file?.url}
          alt={data[0]?.fields?.iconWithContent1?.fields?.description}
          className="img-fluid rett_icon1"
        />
      </Link>
      <Link to="/navigating-life-with-rett#BuildingRettKnowledge">
        <img
          src={data[0]?.fields?.iconWithContent2?.fields?.file?.url}
          alt={data[0]?.fields?.iconWithContent2?.fields?.description}
          className="img-fluid rett_icon2"
        />
      </Link>
      <Link to="/navigating-life-with-rett#RettDiagnosis">
        <img
          src={data[0]?.fields?.iconWithContent3?.fields?.file?.url}
          alt={data[0]?.fields?.iconWithContent3?.fields?.description}
          className="img-fluid rett_icon3"
        />
      </Link>
      <Link to="/navigating-life-with-rett#AssemblingaCareTeam">
        <img
          src={data[0]?.fields?.iconWithContent4?.fields?.file?.url}
          alt={data[0]?.fields?.iconWithContent4?.fields?.description}
          className="img-fluid rett_icon4"
        />
      </Link>
      <Link to="/navigating-life-with-rett#CreatingaCarePlan">
        <img
          src={data[0]?.fields?.iconWithContent5?.fields?.file?.url}
          alt={data[0]?.fields?.iconWithContent5?.fields?.description}
          className="img-fluid rett_icon5"
        />
      </Link>
      <Link to="/navigating-life-with-rett#FamilyCommunitySupports">
        <img
          src={data[0]?.fields?.iconWithContent6?.fields?.file?.url}
          alt={data[0]?.fields?.iconWithContent6?.fields?.description}
          className="img-fluid rett_icon6"
        />
      </Link>
      <Link to="/navigating-life-with-rett#TransitionsChallenges">
        <img
          src={data[0]?.fields?.titleiconWithContent7?.fields?.file?.url}
          alt={data[0]?.fields?.titleiconWithContent7?.fields?.description}
          className="img-fluid rett_icon7"
        />
      </Link>
      <Link to="/navigating-life-with-rett#AchievementsCelebrations">
        <img
          src={data[0]?.fields?.iconWithContent8?.fields?.file?.url}
          alt={data[0]?.fields?.iconWithContent8?.fields?.description}
          className="img-fluid rett_icon8"
        />
      </Link>
      <Link to="/navigating-life-with-rett#SharingYourRemarkableRettLife">
        <img
          src={data[0]?.fields?.iconWithContent9?.fields?.file?.url}
          alt={data[0]?.fields?.iconWithContent9?.fields?.description}
          className="img-fluid rett_icon9"
        />
      </Link>
    </div>
  </div>
  <ul className="navigating_content d-flex text-center">
    <li>
      <Link to="/navigating-life-with-rett#MissedDevelopmentalMilestones">
        {data[0]?.fields?.iconWithContent1?.fields.title}
      </Link>
    </li>
    <li>
      <Link to="/navigating-life-with-rett#BuildingRettKnowledge">
        {data[0]?.fields?.iconWithContent2?.fields.title}
      </Link>
    </li>
    <li>
      <Link to="/navigating-life-with-rett#RettDiagnosis">
        {data[0]?.fields?.iconWithContent3?.fields.title}
      </Link>
    </li>
    <li>
      <Link to="/navigating-life-with-rett#AssemblingaCareTeam">
        {data[0]?.fields?.iconWithContent4?.fields.title}
      </Link>
    </li>
    <li>
      <Link to="/navigating-life-with-rett#CreatingaCarePlan">
        {data[0]?.fields?.iconWithContent5?.fields.title}
      </Link>
    </li>
    <li>
      <Link to="/navigating-life-with-rett#FamilyCommunitySupports">
        {data[0]?.fields?.iconWithContent6?.fields.title}
      </Link>
    </li>
    <li>
      <Link to="/navigating-life-with-rett#TransitionsChallenges">
        {data[0]?.fields?.titleiconWithContent7?.fields.title}
      </Link>
    </li>
    <li>
      <Link to="/navigating-life-with-rett#AchievementsCelebrations">
        {data[0]?.fields?.iconWithContent8?.fields.title}
      </Link>
    </li>
    <li>
      <Link to="/navigating-life-with-rett#SharingYourRemarkableRettLife">
        {data[0]?.fields?.iconWithContent9?.fields.title}
      </Link>
    </li>
  </ul>
</div>

    </div>
  </div>
</section>

  )
}

export default Navigating