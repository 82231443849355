import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { client } from '../../../../client';

function OffenIncludes() {
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getBannerdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'oftenIncludes',locale: languagekey });
      console.log(response)
      setdata(response.items);
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getBannerdata();
  },[])

  return (
    <section>
    <div className="worldwide_section symptomps_section">
      <div className="container">
        <div className="heading_sec">
          <h2>
          {data[0]?.fields?.headingColo1} 
            <span>
            &nbsp;{data[0]?.fields?.headingColor2}
            <br/>
           {data[0]?.fields?.includesNext}
            </span>
          </h2>
          <p className="mt-0">
          {data[0]?.fields?.content}
          </p>
        </div>
        <div className="symptomps_block heading_sec">
          <p className="mb-4">{data[0]?.fields?.cardsHeading} <a href={`#${data[0]?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.metadata.tags[0].sys.id}</sup></a></p>
          <div className="symptomps_box_outer d-flex flex-wrap">
            <div className="symptomps_box_inner">
              <div className="symptomps_box">
                <div
                  className="symptomps_front"
                  style={{
                    background:
                      "url("+data[0]?.fields?.bgimg1?.fields?.file?.url+") 50% 50% no-repeat",
                    backgroundSize: "cover"
                  }}
                >
                  
                  <img
                    src={data[0]?.fields?.icon1?.fields?.file?.url}
                    alt={data[0]?.fields?.icon1?.fields?.description}
                    className="img-fluid w-auto"
                  />
                  <h6>{data[0]?.fields?.icon1?.fields?.title}</h6>
                  <img
                    src="/images/up_arrow.png"
                    alt="up_arrow"
                    className="img-fluid w-auto up_arrow"
                  />
                </div>
                <div className="symptomps_back">
                  <img
                    src={data[0]?.fields?.hwricon1?.fields?.file?.url}
                    alt={data[0]?.fields?.hwricon1?.fields?.description}
                    className="img-fluid w-auto"
                  />{" "}
                  <h5 className="f_26">
                  {data[0]?.fields?.icon1?.fields?.title}
                  </h5>
                  <div className="para_sec">
                    <p>
                    {data[0]?.fields?.icon1?.fields?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="symptomps_box_inner">
              <div className="symptomps_box">
                <div
                  className="symptomps_front"
                  style={{
                    background:
                    "url("+data[0]?.fields?.bgimg2?.fields?.file?.url+") 50% 50% no-repeat",
                    backgroundSize: "cover"
                  }}
                >
                  <img
                    src={data[0]?.fields?.icon2?.fields?.file?.url}
                    alt={data[0]?.fields?.icon2?.fields?.description}
                    className="img-fluid w-auto"
                  />
                  <h6>{data[0]?.fields?.icon2?.fields?.title}</h6>
                  <img
                    src="/images/up_arrow.png"
                    alt="up_arrow"
                    className="img-fluid w-auto up_arrow"
                  />
                </div>
                <div className="symptomps_back">
                  <img
                    src={data[0]?.fields?.hwricon2?.fields?.file?.url}
                    alt={data[0]?.fields?.hwricon2?.fields?.description}
                    className="img-fluid w-auto"
                  />{" "}
                  <h5 className="f_26">
                 { data[0]?.fields?.icon2?.fields?.title}
                  </h5>
                  <div className="para_sec">
                    <p>
                    {data[0]?.fields?.icon2?.fields?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="symptomps_box_inner">
              <div className="symptomps_box">
                <div
                  className="symptomps_front"
                  style={{
                    background:
                    "url("+data[0]?.fields?.bgimg3?.fields?.file?.url+") 50% 50% no-repeat",
                    backgroundSize: "cover"
                  }}
                >
                  <img
                    src={data[0]?.fields?.icon3?.fields?.file?.url}
                    alt={data[0]?.fields?.icon3?.fields?.description}
                    className="img-fluid w-auto"
                  />
                  <h6>
                  {data[0]?.fields?.icon3?.fields?.title}
                  </h6>
                  <img
                    src="/images/up_arrow.png"
                    alt="up_arrow"
                    className="img-fluid w-auto up_arrow"
                  />
                </div>
                <div className="symptomps_back">
                  <img
                    src={data[0]?.fields?.hwricon3?.fields?.file?.url}
                    alt={data[0]?.fields?.hwricon3?.fields?.description}
                    className="img-fluid w-auto"
                  />{" "}
                  <h5 className="f_26">
                  {data[0]?.fields?.icon3?.fields?.title}
                  </h5>
                  <div className="para_sec">
                    <p>
                    {data[0]?.fields?.icon3?.fields?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="symptomps_box_inner">
              <div className="symptomps_box">
                <div
                  className="symptomps_front"
                  style={{
                    background:
                    "url("+data[0]?.fields?.bgimg4?.fields?.file?.url+") 50% 50% no-repeat",
                    backgroundSize: "cover"
                  }}
                >
                  <img
                    src={data[0]?.fields?.icon4?.fields?.file?.url}
                    alt={data[0]?.fields?.icon4?.fields?.description}
                    className="img-fluid w-auto"
                  />
                  <h6>{data[0]?.fields?.icon4?.fields?.title}</h6>
                  <img
                    src="/images/up_arrow.png"
                    alt="up_arrow"
                    className="img-fluid w-auto up_arrow"
                  />
                </div>
                <div className="symptomps_back">
                  <img
                    src={data[0]?.fields?.hwricon4?.fields?.file?.url}
                    alt={data[0]?.fields?.hwricon4?.fields?.description}
                    className="img-fluid w-auto"
                  />{" "}
                  <h5 className="f_26">
                  {data[0]?.fields?.icon4?.fields?.title}
                  </h5>
                  <div className="para_sec">
                    <p>
                    {data[0]?.fields?.icon4?.fields?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="bl_color mt-4">
          {data[0]?.fields?.sinceRettSyndromeIsSoRare} <a href={`#${data[0]?.metadata.tags[1].sys.id}`} class="pointer"><sup>{data[0]?.metadata.tags[1].sys.id}</sup></a>
          </p>
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default OffenIncludes