import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { client } from '../../../../client'
import { NavLink } from 'react-router-dom';

function Syndrome() {
  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getsyndromedata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'syndrome',locale: languagekey })
      setdata(response.items);
      console.log(response.items)
      setLoading(false)
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getsyndromedata();
  },[])
  return (
    <section>
  <div className="syndrome_section">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="syndrome_img">
            <img
              src={data[0]?.fields?.image?.fields?.file?.url}
              alt={`${data[0]?.fields?.image?.fields?.description}`}
              className="img-fluid w-100"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="syndrome_content">
            <div className="heading_sec heading_inner">
              <h2>
                <span>{data[0]?.fields?.heading}</span> {data[0]?.fields?.headingColor?.content[0]?.content[0]?.value}
              </h2>
              <p>{data[0]?.fields?.subHeading}</p>
            </div>
            <ul>
              <li>
                <img src={data[0]?.fields?.listContentWithIcon1?.fields?.file?.url} alt={data[0]?.fields?.listContentWithIcon1?.fields?.description} className="img-fluid" />{" "}
                {data[0]?.fields?.listContentWithIcon1?.fields.title} <a href={`#${data[0]?.fields?.listContentWithIcon1?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.fields?.listContentWithIcon1?.metadata.tags[0].sys.id}</sup></a>
              </li>
              <li>
                <img src={data[0]?.fields?.listContentWithIcon2?.fields?.file?.url} alt={data[0]?.fields?.listContentWithIcon2?.fields?.description} className="img-fluid" />{" "}
                {data[0]?.fields?.listContentWithIcon2?.fields.title} <a href={`#${data[0]?.fields?.listContentWithIcon2?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.fields?.listContentWithIcon2?.metadata.tags[0].sys.id}</sup></a>
              </li>
              <li>
                <img src={data[0]?.fields?.listContentWithIcon3?.fields?.file?.url} alt={data[0]?.fields?.listContentWithIcon3?.fields?.description} className="img-fluid" />{" "}
                {data[0]?.fields?.listContentWithIcon3?.fields.title} <a href={`#${data[0]?.fields?.listContentWithIcon3?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.fields?.listContentWithIcon3?.metadata.tags[0].sys.id}</sup></a>
              </li>
              <li>
                <img src={data[0]?.fields?.listContentWithIcon4?.fields?.file?.url} alt={data[0]?.fields?.listContentWithIcon4?.fields?.description} className="img-fluid" />{" "}
                {data[0]?.fields?.listContentWithIcon4?.fields.title} <a href={`#${data[0]?.fields?.listContentWithIcon4?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.fields?.listContentWithIcon4?.metadata.tags[0].sys.id}</sup></a>
              </li>
              <li>
                <img src={data[0]?.fields?.listContentWithIcon5?.fields?.file?.url} alt={data[0]?.fields?.listContentWithIcon5?.fields?.description} className="img-fluid" />{" "}
                {data[0]?.fields?.listContentWithIcon5?.fields.title} <a href={`#${data[0]?.fields?.listContentWithIcon5?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.fields?.listContentWithIcon5?.metadata.tags[0].sys.id}</sup></a>
              </li>
            </ul>
            <div className="btn_sec">
              <NavLink className='site_btn' to={data[0]?.fields?.link}>
              {data[0]?.fields?.button} <img src="images/btn_arrow.png" alt="btn_arrow" class="img-fluid"></img>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Syndrome