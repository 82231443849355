import React, { useEffect, useState } from 'react';
import Newsletter from './Newsletter';
import { client } from '../../client';
import { Link, NavLink } from 'react-router-dom';
import Footerbottom from './Footerbottom';
import InjectScriptFromAPI from './InjectScriptFromAPI';

function Footer() {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [tag, setTag] = useState('');
  const languagekey = localStorage.getItem('locale');

  const getfooterdata = async () => {
    try {
      const response = await client.getEntries({ content_type: 'footer', locale: languagekey });
      setData(response.items);
    } catch (error) {
      console.error("Error fetching footer data:", error);
    }
  };

  const getheaderdata = async () => {
    try {
      const response = await client.getEntries({ content_type: 'topNav', locale: languagekey });
      setData2(response.items);
    } catch (error) {
      console.error("Error fetching header data:", error);
    }
  };

  const getgtatag = async () => {
    try {
      const response = await client.getEntries({ content_type: 'gtaTag', locale: languagekey });
      setTag(response.items[0]?.fields?.scriptcodefull);
    } catch (error) {
      console.error("Error fetching GTA tag data:", error);
    }
  };

  // Fetch all data in parallel and set loading to false only after all are done
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getfooterdata(), getheaderdata(), getgtatag()]);
      setLoading(false);  // Set loading to false after all data is fetched
    };
    fetchData();
  }, []);

  // Show loading spinner or message while data is being fetched
  if (isLoading) {
    return <div></div>;  // Add your loading spinner here
  }

  return (
    <>
      <Newsletter />
      <footer>
        <div className="footer_section">
          <div className="container">
            <div className="footer_row d-flex align-items-center">
              <div className="footer_col1">
                <div className="footer_logo">
                  <img src={data2[0]?.fields?.icon?.fields?.file?.url} alt="candalogo" className='img-fluid' />
                  <p>{data2[0]?.fields?.heading}</p>
                </div>
              </div>
              <div className="footer_col2">
                <div className="footer_menu">
                  <ul>
                    {data[0]?.fields?.links.map((datavalue, index) => (
                      <li key={index}>
                        <NavLink to={data[0]?.fields?.nameLinks[index]}>{datavalue}</NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="copyright_sec d-flex">
                  <p>{data[0]?.fields?.footerbottomheading}</p>
                  <ul className="d-flex justify-content-center align-items-center">
                    <li><Link to={data[0]?.fields?.privacypolicylink}>{data[0]?.fields?.privacypolicyy}</Link></li>
                    <li><Link to={data[0]?.fields?.termsconditionlink}>{data[0]?.fields?.termsandconditionn}</Link></li>
                  </ul>
                </div>
                <div className='copyrighbe' style={{ fontSize: "15px", color: "white" }}>
                  <p>{data[0]?.fields?.code}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <InjectScriptFromAPI datacode={tag} />
    </>
  );
}

export default Footer;