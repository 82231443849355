import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../../client';



function Canadianhuub() {

  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'canadianHub',locale: languagekey})
      setdata(response.items);
      setLoading(false)
    }
    catch(error){
    
    }
  }
  useEffect(()=>{
    getdata();
  },[])
  return (
    <>
    {
     isLoading? (<>Loading....</>):( 
    <section>
  <div className="remarkable_section living_section about_section">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="remarkable_content">
            <div className="heading_sec heading_inner">
              <h2>
                <span>{data[0]?.fields?.headingColor1}</span> {data[0]?.fields?.headingColor2}
              </h2>
            </div>
            <div className="para_sec">
              <p>
              {data[0]?.fields?.content1}
              </p>
              <p>
              {data[0]?.fields?.content2}
              </p>
              <p>
              {data[0]?.fields?.content3}
              </p>
              <p>
              {data[0]?.fields?.content4}
              </p>
              <p>
              {data[0]?.fields?.content5}
              </p>
            </div>
          </div>
        </div>

        
        <div className="col-lg-6">
          <div className="remarkable_img">
            <span className="img1 mt-0">
              <img
                src= {data[0]?.fields?.image?.fields?.file?.url}
                alt={data[0]?.fields?.image?.fields?.description}
                className="img-fluid w-100"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
     )}
     </>
  )
}

export default Canadianhuub