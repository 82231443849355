import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { client } from '../../../../client';

function Timeline() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const languagekey = localStorage.getItem('locale');

  const scrollFunction = () => {
    const hash = window.location.hash.substring(1); // Get hash value without the '#'
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const getTimelineData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'timeline',locale: languagekey });
      setData(response.items);
      console.log(response);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getTimelineData();
  }, []);

  
  useEffect(() => {
    if (!loading) {
      scrollFunction();
    }
  }, [data, loading]);

  useEffect(() => {
    const hash = window.location.hash.substring(1); // Get hash value without the '#'
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [location.pathname]); // Run when the pathname changes

  return (
    <div className="timeline_sec">
      {data
        .sort((a, b) => a?.fields.order - b?.fields.order)
        .map((item, index) => (
          <div className="row" id={item?.fields?.id} key={index}>
            <div className="col-md-6">
              <div className="timeline_img">
                <img
                  src={item?.fields?.image?.fields?.file?.url}
                  alt={item?.fields?.image?.fields?.description}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="timeline_content">
                <h4>{item?.fields?.title}</h4>
                <span>
                  <img
                    src={item?.fields?.icon?.fields?.file?.url}
                    alt={item?.fields?.icon?.fields?.description}
                    className="img-fluid"
                  />
                </span>
                <div className="timeline_para">
                  {item?.fields?.description?.content.map((data2, index) => (
                    <p key={index}>
                      {data2?.content[0]?.value} <u>{data2?.content[1]?.value}</u>
                      {data2?.content[2]?.value}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Timeline;
