import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { client } from '../../../../client';
import { NavLink } from 'react-router-dom';
import Navigating from './Navigating';

function Remarkable() {
  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getremarkabledata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'remarkableSection',locale: languagekey })
      setdata(response.items);
      setLoading(false)
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getremarkabledata();
  },[])


  return (
    <section>
  <div className="remarkable_section">
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <div className="remarkable_content">
            <div className="heading_sec heading_inner">
              <h2>
                <span>{data[0]?.fields?.heading}</span> {data[0]?.fields?.headingColor}
              </h2>
              <p>
              {data[0]?.fields?.content}
              </p>
            </div>
            <div className="para_sec">
              <p>
              {data[0]?.fields?.content2.content[0].content[0].value}
              </p>
              <p>
              {data[0]?.fields?.content2.content[1].content[0].value}
              </p>
            </div>
            <div className="btn_sec">
              <NavLink className='site_btn' to={data[0]?.fields?.buttonlink}>
                {data[0]?.fields?.buttonText} <img src="images/btn_arrow.png" alt="btn_arrow" class="img-fluid"></img>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="remarkable_img d-flex">
            <span className="img1">
              <img
                src={`${data[0]?.fields?.image1?.fields?.file?.url}`}
                alt={`${data[0]?.fields?.image1?.fields?.description}`}
                className="img-fluid w-100"
              />
            </span>
            <span className="img2">
              <img
                src={`${data[0]?.fields?.image2?.fields?.file?.url}`}
                alt={`${data[0]?.fields?.image2?.fields?.description}`}
                className="img-fluid w-100"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}

export default Remarkable