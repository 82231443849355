import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../../client'

function OccursWorldwide() {
    const [data,setdata]=useState([])
    const languagekey = localStorage.getItem('locale');
    const getBannerdata = async (e)=>{
      try {
        const response = await client.getEntries({ content_type: 'occursWorldwide',locale: languagekey })
        setdata(response.items);
        console.log(response.items);
      }
      catch(error){
        // console.log(error);
      }
    }
    useEffect(()=>{
      getBannerdata();
    },[])
  return (
    <section>
    <div className="banner_section worldwide_section">
      <div className="banner_slide">
        <img src="/images/ww_bg.png" alt="ww_bg" className="img-fluid w-100" />
        <div className="banner_outer">
          <div className="container">
            <div className="heading_sec">
              <h2>
                {data[0]?.fields?.headingColo1} 
                <span class="d-block" style={{color: "#533D7E"}}>{data[0]?.fields?.headingColor2}</span>
              </h2>
              
              <h3>{data[0]?.fields?.content}</h3> 
              <h2>
              <span class="d-block" style={{color: "#533D7E"}}> {data[0]?.fields?.subContent} <a href={`#${data[0]?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.metadata.tags[0].sys.id}</sup></a></span> 
              </h2>
              <p>
               {data[0]?.fields?.hashvalue} {data[0]?.fields?.subContent2} <a href={`#${data[0]?.metadata.tags[1].sys.id}`} class="pointer"><sup>{data[0]?.metadata.tags[1].sys.id}</sup></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default OccursWorldwide