import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../../client';
import Underconstructions from './Underconstructions';

function Tabs() {

  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'tabsresources',locale: languagekey })
      setdata(response.items);
      setLoading(false)
     
     
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getdata();
  },[])

  return (
<>
{
    isLoading? (<>Loading....</>):(
      data.length === 0 ? (<Underconstructions/>):(
        <section>
  <div className="resources_faq_section">
    <div className="container">
    <div className="accordion" id="accordionExample">
  {data.sort((a, b) => a?.fields.order - b?.fields.order).map((item, index) => {
    const isFirstItem = index === 0; // Check if it's the first item

    return (
      <div className="accordion-item" key={item?.fields?.order}>
        <h2 className="accordion-header">
          <button
            className={`accordion-button ${isFirstItem ? '' : 'collapsed'}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapseOne${item?.fields?.order}`}
            aria-expanded={isFirstItem}
            aria-controls={`collapseOne${item?.fields?.order}`}
          >
            {item?.fields?.title}
          </button>
        </h2>
        <div
          id={`collapseOne${item?.fields?.order}`}
          className={`accordion-collapse collapse ${isFirstItem ? 'show' : ''}`}
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <p className="mb-0">
              {item?.fields?.content}
            </p>
          </div>
        </div>
      </div>
    );
  })}
</div>

    </div>
  </div>
</section>

      ) 

    )}

</>
  )
}

export default Tabs