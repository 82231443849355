import React, { useEffect, useState } from 'react';
import Banner from '../containers/Banner';
import { client } from '../../../client';
import Everyperson from './sections/Everyperson';
import Underdevelopment from './sections/Underdevelopment';
// import Testimonials from './sections/Testimonials';

function Retstories() {
  const [isLoading, setLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);
  const [everyPersonData, setEveryPersonData] = useState([]);
  const [underDevelopmentData, setUnderDevelopmentData] = useState([]);
  const languagekey = localStorage.getItem('locale');

  const getBannerData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'bannerRettStories', locale: languagekey });
      setBannerData(response.items);
    } catch (error) {
      console.error('Error fetching banner data:', error);
    }
  };

  const getEveryPersonData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'everyPersonContent', locale: languagekey });
      setEveryPersonData(response.items);
    } catch (error) {
      console.error('Error fetching Everyperson data:', error);
    }
  };

  const getUnderDevelopmentData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'underDevelopmentContent', locale: languagekey });
      setUnderDevelopmentData(response.items);
    } catch (error) {
      console.error('Error fetching Underdevelopment data:', error);
    }
  };

  // Fetch all necessary data in parallel
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getBannerData(), getEveryPersonData(), getUnderDevelopmentData()]);
      setLoading(false); // Set loading to false after all data is fetched
    };
    fetchData();
  }, []);

  // Show loading spinner or message while data is being fetched
  if (isLoading) {
    return <div style={loadingStyle}>
    <img src="/images/loading-7528_128.gif" alt="Loading..." style={gifStyle} />
  </div>// You can replace this with a custom spinner component
  }

  return (
    <div>
      <Banner data={bannerData} />
      <Everyperson data={everyPersonData} />
      <Underdevelopment data={underDevelopmentData} />
      {/* <Testimonials /> */}
    </div>
  );
}

// CSS-in-JS styles for centering the loading GIF
const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full height of the viewport
};

const gifStyle = {
  width: '100px',  // Adjust size as needed
  height: '100px',
};

export default Retstories;
