import React, { useState, useEffect } from 'react';
import Canadianhuub from './Sections/Canadianhuub';
import { client } from '../../../client';
import Banner from '../containers/Banner';

// CSS-in-JS styles for centering the loading GIF
const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full height of the viewport for perfect centering
};

const gifStyle = {
  width: '100px',  // Adjust the size of the GIF as needed
  height: '100px',
};

function About() {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const languagekey = localStorage.getItem('locale');

  const getBannerData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'bannerAbout', locale: languagekey });
      setData(response.items);
      setLoading(false);  // Set loading to false when data is fetched
    } catch (error) {
      console.error('Error fetching banner data:', error);
    }
  };

  useEffect(() => {
    getBannerData();
  }, []);

  // Show loading GIF while data is being fetched
  if (isLoading) {
    return (
      <div style={loadingStyle}>
        <img src="/images/loading-7528_128.gif" alt="Loading..." style={gifStyle} />
      </div>
    );
  }

  return (
    <>
      <Banner data={data} />
      <Canadianhuub />
    </>
  );
}

export default About;
