import React from 'react';
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../../client';

function Underconstructions() {

  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'underdevelopmentresources',locale: languagekey })
      setdata(response.items);
      setLoading(false)
     
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getdata();
  },[])

  return (
   <>
   {
    isLoading? (<>Loading....</>):( 
   <section>
  <div className="dev_section resources_section">
    <div className="dev_slide">
      <img
        src="/images/resources_cone_bg.jpg"
        alt="cone_bg"
        className="img-fluid w-100"
      />
      <div className="dev_outer">
        <div className="container">
          <div className="heading_sec">
            <img
              src="/images/resources_cone_img.png"
              alt="resources_cone_img"
              className="img-fluid cone_back"
            />
            <img src="/images/cone.png" alt="cone" className="img-fluid" />
            <p className="mt-4">
            {data[0]?.fields?.discription}
            </p>
            <h2 className="mt-0">{data[0]?.fields?.title}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    )}

   </>
  )
}

export default Underconstructions