import React, { useState, useEffect } from 'react';
import { client } from '../../../client';
import Headlines from './sections/Headlines';
import { useParams } from 'react-router-dom';

// CSS-in-JS styles for centering the loading GIF
const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full height of the viewport for perfect centering
};

const gifStyle = {
  width: '100px',  // Adjust the size of the GIF as needed
  height: '100px',
};

function StorieDetailes() {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { id: storieid } = useParams();
  const languagekey = localStorage.getItem('locale');

  async function fetchEntry(entryId, locale) {
    try {
      const entry = await client.getEntry(entryId, {
        locale: locale // Pass the locale key here
      });
      setData(entry.fields);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching entry:', error);
      setLoading(false); // Ensure loading is set to false even if there is an error
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchEntry(storieid, languagekey);
  }, [storieid, languagekey]);

  // Show loading GIF while data is being fetched
  if (isLoading) {
    return (
      <div style={loadingStyle}>
        <img src="/images/loading-7528_128.gif" alt="Loading..." style={gifStyle} />
      </div>
    );
  }

  return (
    <>
      <Headlines data={data} />
    </>
  );
}

export default StorieDetailes;
