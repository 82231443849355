import React, { useState, useEffect } from 'react';
import Banner from '../containers/Banner';
import Remarkable from './sections/Remarkable';
import Syndrome from './sections/Syndrome';
import Navigating from './sections/Navigating';
import { client } from '../../../client';
import Refrences from './sections/Refrences';
const contentful = require('contentful-management');

function Home() {
  const client2 = contentful.createClient({
    accessToken: 'CFPAT-2QPAQbSn_1mLQNoHW6sj9O4V1A2rzTD9-Yfls6w-MhM'
  });
  
  const languagekey = localStorage.getItem('locale');

  const [isLoading, setLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);
  const [remarkableData, setRemarkableData] = useState([]);
  const [syndromeData, setSyndromeData] = useState([]);
  const [navigatingData, setNavigatingData] = useState([]);
  const [referencesData, setReferencesData] = useState([]);

  const getBannerData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'sliderHome', locale: languagekey });
      setBannerData(response.items);
    } catch (error) {
      console.error('Error fetching banner data:', error);
    }
  };

  const getRemarkableData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'remarkableSection', locale: languagekey });
      setRemarkableData(response.items);
    } catch (error) {
      console.error('Error fetching remarkable section data:', error);
    }
  };

  const getSyndromeData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'syndromeSection', locale: languagekey });
      setSyndromeData(response.items);
    } catch (error) {
      console.error('Error fetching syndrome section data:', error);
    }
  };

  const getNavigatingData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'navigatingSection', locale: languagekey });
      setNavigatingData(response.items);
    } catch (error) {
      console.error('Error fetching navigating section data:', error);
    }
  };

  const getReferencesData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'referencesSection', locale: languagekey });
      setReferencesData(response.items);
    } catch (error) {
      console.error('Error fetching references data:', error);
    }
  };

  // Fetch all necessary data in parallel
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        getBannerData(),
        getRemarkableData(),
        getSyndromeData(),
        getNavigatingData(),
        getReferencesData()
      ]);
      setLoading(false);  // Set loading to false after all data is fetched
    };
    fetchData();
  }, []);

  // Show loading spinner or message while data is being fetched
  if (isLoading) {
    return (
      <div style={loadingStyle}>
        <img src="/images/loading-7528_128.gif" alt="Loading..." style={gifStyle} />
      </div>
    );
  }

  return (
    <>
      <Banner data={bannerData} />
      <Remarkable data={remarkableData} />
      <Syndrome data={syndromeData} />
      <Navigating data={navigatingData} />
      <Refrences data={referencesData} />
    </>
  );
}

// CSS-in-JS styles for centering the loading GIF
const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full height of the viewport
};

const gifStyle = {
  width: '100px',  // Adjust size as needed
  height: '100px',
};

export default Home;
