import React ,{useEffect} from 'react'
import { client } from '../../../../client';
import { useState } from 'react';

function Raredisorder() {
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getBannerdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'rearDisorder',locale: languagekey });
      setdata(response.items);
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getBannerdata();
  },[])
  return (
    <section>
  <div className="disorder_section">
    <div className="container">
      <div className="remarkable_content">
        <div className="heading_sec heading_inner">
          <h2>
            {data[0]?.fields?.heading1Color} <span> {data[0]?.fields?.isa} <br/>
           
              {" "}
              {data[0]?.fields?.heading2Color}
            </span>
          </h2>
        </div>
        <ul className="d-flex flex-wrap">
          <li>
            <span>
              <img
                src={data[0]?.fields?.icon1?.fields?.file?.url}
                alt={data[0]?.fields?.icon1?.fields?.description}
                className="img-fluid w-auto"
              />
            </span>
            <p>
              <b>{data[0]?.fields?.icon1?.fields?.title}</b> {data[0]?.fields?.icon1?.fields?.description} <a href={`#${data[0]?.fields?.icon1?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.fields?.icon1?.metadata.tags[0].sys.id}</sup></a>
            </p>
          </li>
          <li>
            <span>
              <img
                src={data[0]?.fields?.icon2?.fields?.file?.url}
                alt={data[0]?.fields?.icon2?.fields?.description}
                className="img-fluid w-auto"
              />
            </span>
            <p>
              <b>{data[0]?.fields?.icon2?.fields?.title}</b> {data[0]?.fields?.icon2?.fields?.description} <a href={`#${data[0]?.fields?.icon2?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.fields?.icon2?.metadata.tags[0].sys.id}</sup></a>
            </p>
          </li>
          <li>
            <span>
              <img
                src={data[0]?.fields?.icon3?.fields?.file?.url}
                alt={data[0]?.fields?.icon3?.fields?.description}
                className="img-fluid w-auto"
              />
            </span>
            <p>
              <b>{data[0]?.fields?.icon3?.fields?.title}</b> {data[0]?.fields?.icon3?.fields?.description} <a href={`#${data[0]?.fields?.icon3?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.fields?.icon3?.metadata.tags[0].sys.id}</sup></a>
            </p>
          </li>
          <li>
            <span>
              <img
                src={data[0]?.fields?.icon4?.fields?.file?.url}
                alt={data[0]?.fields?.icon4?.fields?.description}
                className="img-fluid w-auto"
              />
            </span>
            <p>
              <b>{data[0]?.fields?.icon4?.fields?.title}</b> {data[0]?.fields?.icon4?.fields?.description}<a href={`#${data[0]?.fields?.icon4?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.fields?.icon4?.metadata.tags[0].sys.id}</sup></a>
            </p>
          </li>
          <li>
            <span>
              <img
                src={data[0]?.fields?.icon5?.fields?.file?.url}
                alt={data[0]?.fields?.icon5?.fields?.description}
                className="img-fluid w-auto"
              />
            </span>
            <p>
              <b>{data[0]?.fields?.icon5?.fields?.title}</b> {data[0]?.fields?.icon5?.fields?.description} <a href={`#${data[0]?.fields?.icon5?.metadata.tags[0].sys.id}`} class="pointer"><sup>{data[0]?.fields?.icon5?.metadata.tags[0].sys.id}</sup></a>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

  )
}

export default Raredisorder