import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Footer from './components/common/Footer';
import Header from './components/common/Header';
import Home from './components/pages/Home/Home';
import Retstories from './components/pages/Rettstories/Retstories';
import Rettsyndrome from './components/pages/Syndrome/Rettsyndrome';
import Navigating from './components/pages/Navigating/Navigating';
import Resources from './components/pages/Resources/Resources';
import About from './components/pages/About/About';
import Contact from './components/pages/Contact/Contact';
import Rettstoriesdetails from './components/pages/Rettstoriesdetails/StorieDetaile';
import Search from './components/pages/Search/Search';


function App() {
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;
  
  

  
 
  
  
 
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rett-stories" element={<Retstories />} />
        <Route path="/what-is-rett-syndrome" element={<Rettsyndrome />} />
        <Route path="/navigating-life-with-rett" element={<Navigating />} />
        <Route path="/rett-resources" element={<Resources />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/stories-details/:id" element={<Rettstoriesdetails />} />
        <Route path="/search/:id" element={<Search />} />

        {/* French routes */}
        <Route path="/fr" element={<Home />} />
        <Route path="/rett-stories/fr" element={<Retstories />} />
        <Route path="/what-is-rett-syndrome/fr" element={<Rettsyndrome />} />
        <Route path="/navigating-life-with-rett/fr" element={<Navigating />} />
        <Route path="/rett-resources/fr" element={<Resources />} />
        <Route path="/about-us/fr" element={<About />} />
        <Route path="/contact-us/fr" element={<Contact />} />
        <Route path="/stories-details/:id/fr" element={<Rettstoriesdetails />} />
        <Route path="/search/:id/fr" element={<Search />} />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
