import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { client } from '../../../../client';

function Everyperson() {
  const [isLoading, setLoading] = useState(false);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const [splitdata,setsplitdata] = useState([]);
  const geteverypersondata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'everyPersion',locale: languagekey });
      setdata(response.items);
      setLoading(true)
      const wordIndex = response.items[0]?.fields?.heading.indexOf(`remarkable`);
      if (wordIndex === -1) {
        // If the word is not found, return the original sentence as the first part
        return [response.items[0]?.fields?.heading, '', ''];
      }
      const part1 = response.items[0]?.fields?.heading.slice(0, wordIndex).trim();
  const part2 = 'remarkable';
  const part3 = response.items[0]?.fields?.heading.slice(wordIndex + `remarkable`.length + 1).trim();
  setsplitdata([part1,part2,part3])

    }
    catch(error){
      // console.log(error);
    }
  }
  const splitheading = ()=>{

    {data[0]?.fields?.heading?.replace('remarkable', <span>remarkable</span> )}
  }


  useEffect(()=>{
    geteverypersondata();

    
  },[])
   
  
  return (
    <>
    {
      data? (<>  <section>
        <div className="remarkable_section living_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="remarkable_content">
                  <div className="heading_sec heading_inner">
                    <h2>
                      {languagekey=='fr'?(<>{data[0]?.fields?.heading}</>):(<>{splitdata[0]} <span>{splitdata[1]}</span> {splitdata[2]}</>)}
                      
                      {/* {data[0]?.fields?.heading} */}
                    </h2>
                  </div>
                  <div className="para_sec">
                    <p>
                    {data[0]?.fields?.pera1}
                    </p>
                    <p>
                    {data[0]?.fields?.pera2}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="remarkable_img d-flex">
                  <span className="img1 mt-0">
                    <img
                      src={data[0]?.fields?.img1?.fields?.file?.url}
                      alt={data[0]?.fields?.img1?.fields?.description}
                      className="img-fluid w-100"
                    />
                  </span>
                  <span className="img2">
                    <img
                     src={data[0]?.fields?.img2?.fields?.file?.url}
                      alt={data[0]?.fields?.img2?.fields?.description}
                      className="img-fluid w-100"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section></>):(<>
      Loading...
      </>)
    }
    </>
    
  
  )
}

export default Everyperson