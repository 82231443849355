import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../../client'

function Rettjourny() {
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
    const getretjournydata = async (e)=>{
      try {
        const response = await client.getEntries({ content_type: 'rettJourney',locale: languagekey})
     
        setdata(response.items);
      }
      catch(error){
        // console.log(error);
      }
    }
    useEffect(()=>{
      getretjournydata();
    },[])

  return (
    <div class="heading_sec">
    <h2><span>{data[0]?.fields?.titleColor1}</span> {data[0]?.fields?.titleColor2}</h2>
    <div class="para_sec">
      <p>{data[0]?.fields?.content}</p>
    </div>
  </div>
  )
}

export default Rettjourny