import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../../client'

 

function Cookiecutter() {
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getretjournydata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'cookieCutter',locale: languagekey})
      setdata(response.items);
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getretjournydata();
  },[])

  return (
  <div class="banner_block_section">
    <div class="container">
      <div class="banner_block">
        <div class="para_sec">
          <p><b>{data[0]?.fields?.bolderText}</b> {data[0]?.fields?.content}</p>
          <p>{data[0]?.fields?.belowheading}</p>
          <div class="heading_sec">
            <h2><span>{data[0]?.fields?.color1Text}</span> {data[0]?.fields?.color2Text}</h2>
          </div>
        </div>
      </div>
    </div>
  </div> 
  )
}

export default Cookiecutter