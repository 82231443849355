import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { client } from '../../../../client';


function Refrences() {
    const [data,setdata]=useState([])
    const languagekey = localStorage.getItem('locale');
    const getremarkabledata = async (e)=>{
      try {
        const response = await client.getEntries({ content_type: 'refrencesyndrome',locale: languagekey })
        setdata(response.items);
       console.log(response.items);
      }
      catch(error){
        // console.log(error);
      }
    }
    useEffect(()=>{
      getremarkabledata();
    },[])

  return (
   <>
    <section style={{background: "#8080801f"}}>
        <div className='container py-2'  >
        {
    data
        .sort((a, b) => a.fields.order - b.fields.order) // Sort by the 'order' field
        .map((data, index) => {
            return (
                <div className='py-1' style={{fontSize: "13px"}} id={data.fields.id} key={index}>
                   <sup>{data.fields.id}</sup> {data.fields.input}
                </div>
            );
        })
}
        </div>
        
    
    </section>
    </>
  )
}

export default Refrences