import React, { useState, useEffect } from 'react';
import { client } from '../../../client';
import Banner from '../containers/Banner';
import Raredisorder from './Sections/Raredisorder';
import OccursWorldwide from './Sections/OccursWorldwide';
import OffenIncludes from './Sections/OffenIncludes';
import Refrences from './Sections/Refrences';

// CSS-in-JS styles for centering the loading GIF
const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full height of the viewport for perfect centering
};

const gifStyle = {
  width: '100px',  // Adjust the size of the GIF as needed
  height: '100px',
};

function Rettsyndrome() {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const languageKey = localStorage.getItem('locale');

  const getBannerData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'syndromeBanner', locale: languageKey });
      setData(response.items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching banner data:', error);
    }
  };

  useEffect(() => {
    getBannerData();
  }, [languageKey]);

  // Show loading spinner or message while data is being fetched
  if (isLoading) {
    return (
      <div style={loadingStyle}>
        <img src="/images/loading-7528_128.gif" alt="Loading..." style={gifStyle} />
      </div>
    );
  }

  return (
    <div>
      <Banner data={data} />
      <Raredisorder />
      <OccursWorldwide />
      <OffenIncludes />
      <Refrences />
    </div>
  );
}

export default Rettsyndrome;
