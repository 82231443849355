import React, { useEffect, useState } from 'react';
import { client } from '../../client';
import { NavLink } from 'react-router-dom';
import Topnav from './Topnav';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [logoUrl, setLogoUrl] = useState('');
  const localekey = localStorage.getItem('locale');

  const handleMenuToggle = () => {
    setIsActive(!isActive);
  };

  const handleCloseMenu = () => {
    setIsActive(false);
  };

  const getHeaderData =  async() => {
    // const response =  client.getEntries({ content_type: 'header', locale: localekey });
    // setData(response.items);
    // setLoading(false);

    try {
      const response = await client.getEntries({ content_type: 'header', locale: localekey });
      setData(response.items);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getLogo = async () => {
    try {
      const response = await client.getEntries({ content_type: 'logo', locale: localekey });
      const logo = response.items[0]?.fields?.logo?.fields?.file?.url;
      setLogoUrl(logo);
    } catch (error) {
      console.error(error);
    }
  };

  const setFavicon = (url) => {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = url;
      document.head.appendChild(newLink);
    } else {
      link.href = url;
    }
  };

  useEffect(() => {
    getHeaderData();
    getLogo();
  }, [localekey]);

  useEffect(() => {
    if (logoUrl) {
      setFavicon(logoUrl);  // Set the favicon when the logo URL is available
    }
  }, [logoUrl]);

  useEffect(() => {
    if (isActive) {
      document.body.classList.add('active_menu');
    } else {
      document.body.classList.remove('active_menu');
    }
    return () => {
      document.body.classList.remove('active_menu');
    };
  }, [isActive]);

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <>
          <header className="header-area">
            <Topnav />
            <div className="btm_nav_sec">
              <div className="container">
                <div className="btm_nav_inner d-flex align-items-center justify-content-between">
                  <div className="logo_sec">
                    <NavLink to={localekey !== 'fr' ? '/' : '/fr'}>
                      <img
                        src={isLoading ? 'images/loading-7528_128.gif' : logoUrl}
                        alt="logo"
                        className="img-fluid"
                      />
                    </NavLink>
                  </div>
                  <div className="main_menu_sec">
                    <nav className="main-nav bottom-nav norm_menu">
                      <ul className="nav ps-0">
                        <a className="cross_icon" onClick={handleCloseMenu}>
                          <span />
                          <span />
                          <span />
                        </a>
                        {data.sort((a, b) => a.fields.order - b.fields.order).map((item, index) => (
                          <li key={index}>
                            <NavLink to={item?.fields?.menuLink} onClick={handleCloseMenu}>
                              {item?.fields?.menuName}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                      <div className="menu_icon_sec">
                        <a onClick={handleMenuToggle}>
                          <span />
                          <span />
                          <span />
                        </a>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </>
      )}
    </>
  );
};

export default Header;
