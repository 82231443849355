import React, { useEffect, useState } from 'react';
import { client } from '.././../client';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Newsletter() {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [email, setEmail] = useState('');
  const [data2,setdata2]=useState([])
  const [isEmailValid, setIsEmailValid] = useState(false);
  const languagekey = localStorage.getItem('locale');

  const getHeaderData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'subscribe' ,locale: languagekey});
      setData(response.items);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handlePutRequest = async (e) => {
    e.preventDefault();

    const url = 'https://api.contentful.com/spaces/kh9oqq79x6cc/environments/master/entries';
    const token = 'CFPAT-2QPAQbSn_1mLQNoHW6sj9O4V1A2rzTD9-Yfls6w-MhM';
    const bodyData = {
      fields: {
        email: {
          'en-US': email
        }
      }
    };

    console.log(bodyData);

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/vnd.contentful.management.v1+json',
      'X-Contentful-Content-Type': 'newsLetterform',
    };

    try {
      const response = await axios.post(url, bodyData, { headers });
      toast.success(data2[0]?.fields?.text , {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setEmail('');
      setIsEmailValid(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getInput = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setEmail(inputValue);
    console.log(validateEmail(inputValue))
    setIsEmailValid(validateEmail(inputValue));
  };

 

  const getthankxdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'notificationSuccessMessage',locale: languagekey })
      setdata2(response.items);
      setLoading(false)
    }
    catch(error){
      console.log(error);
    }
  }

  
  useEffect(() => {
    getHeaderData();
    getthankxdata();
  }, []);

  return (
    <section>
      <div className="newsletter_section">
        <div className="container">
          <div className="newsletter_content">
            <div className="heading_sec heading_inner">
              <p className="mb-0">
                {data[0]?.fields.content}
              </p>
            </div>
            <div className="nl_block">
              <input type="email" className="input_sec" value={email} onChange={getInput}  placeholder={data[0]?.fields.placeholder}/>
              <div className="btn_sec ms-2">
                <NavLink
                  className={`site_btn ${!isEmailValid ? 'disabled' : ''}`}
                  onClick={isEmailValid ? handlePutRequest : null}
                  // to={data[0]?.fields.buttonLink}
                  style={{ pointerEvents: !isEmailValid ? 'none' : 'auto' }}
                >
                  {data[0]?.fields.button}

                 
                  <img src="/images/btn_arrow.png" alt="btn_arrow" className="img-fluid   " />
                </NavLink>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;
